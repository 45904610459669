<template>
  <div>
    <BulkAccessionsView></BulkAccessionsView>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const BulkAccessionsView = defineAsyncComponent(() =>
  import("@/components/bulkAccessions/BulkAccessionsView")
);
export default {
  components: {
    BulkAccessionsView,
  },
};
</script>
